import React from 'react'
import styled from 'styled-components'
import { fluidRange } from 'polished'
import {
  color,
  fontFamily,
  fontWeight,
  lineHeight,
  space,
  textAlign,
  width,
} from 'styled-system'

import brushStroke01 from 'src/images/brushStrokes/bs01.png'
import { FlexGrid } from 'src/components'

const min = { width: '320px', base: 16, contrast: 1.2 }
const max = { width: '1280px', base: 18, contrast: 1.6 }

export const CustomList = styled(
  ({
    width,
    m,
    mt,
    mr,
    mb,
    ml,
    mx,
    my,
    p,
    pt,
    pr,
    pb,
    pl,
    px,
    py,
    fontFamily,
    fontSize,
    fontWeight,
    textAlign,
    lineHeight,
    color,
    bg,
    ...props
  }) => <FlexGrid {...props} />
)`
  width: 100%;
  ${space};
  ${fontFamily};
  ${props =>
    fluidRange(
      {
        prop: 'fontSize',
        fromSize:
          props.fontSize > 1
            ? Math.pow(min.contrast, props.fontSize - 1) * min.base + 'px'
            : min.base * (props.fontSize > 0 ? 1 : 4 / 5) + 'px',
        toSize:
          props.fontSize > 1
            ? Math.pow(max.contrast, props.fontSize - 1) * max.base + 'px'
            : max.base * (props.fontSize > 0 ? 1 : 4 / 5) + 'px',
      },
      min.width,
      max.width
    )};
  ${fontWeight};
  ${textAlign};
  letter-spacing: -0.005em;
  ${lineHeight};
  ${color};
  list-style: none;
  padding-left: 0;

  li {
    ${width};
    div {
      position: relative;
      padding-left: 45px;
      &::before {
        position: absolute;
        top: 5px;
        left: 0;
        content: '';
        width: 30px;
        height: 20px;
        background-image: url(${brushStroke01});
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
`

CustomList.defaultProps = {
  m: 0,
  fontFamily: 'sans',
  fontSize: 1,
  fontWeight: 600,
  lineHeight: 1.5,
  color: 'text.default',
}
